/* Hover effect for social icons */
.hover-scale {
    transition: transform 0.3s ease;
  }
  
  .hover-scale:hover {
    transform: scale(1.2);
  }
  
  /* Underline effect for links */
  .hover-underline {
    position: relative;
    text-decoration: none;
  }
  
  .hover-underline::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #bfff00;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }
  
  .hover-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }